import React, { lazy, Suspense } from 'react';
import { PiletApi } from '@sharefiledev/sharefile-appshell';
import { Spin } from 'antd';
import { BlockNames } from '../block.types';

export function registerBlocks(piral: PiletApi) {
	const LazyLaunchFlowButton = lazy(() => import('./LaunchFlowButton'));
	piral.registerExtension(BlockNames.LaunchFlow, props => (
		<Suspense fallback={<Spin />}>
			<LazyLaunchFlowButton {...props} />
		</Suspense>
	));

	const LazyWorkflowVisualization = React.lazy(() => import('./WorkflowVisualization'));
	piral.registerExtension(BlockNames.WorkflowVisualization, props => (
		<React.Suspense fallback={<Spin />}>
			<LazyWorkflowVisualization {...props} />
		</React.Suspense>
	));
}
