import React from 'react';
import { Skeleton, Spin } from 'antd';
import { SkeletonButtonProps } from 'antd/es/skeleton/Button';
import {
	AutomationsButtonProps,
	ContextualActionsProps,
	ContextualWorkflowListProps,
	LoadFlowsProps,
} from '../sdk';
import { t } from '../util';

const LazyContextualWorkflowList = React.lazy(() => import('./ContextualWorkflowList'));

export const AsyncContextualWorkflowList: React.FC<
	ContextualWorkflowListProps
> = props => {
	return (
		<React.Suspense fallback={<Spin />}>
			<LazyContextualWorkflowList {...props} />
		</React.Suspense>
	);
};

const LazyContextualActions = React.lazy(
	() => import('./contextual-actions/ContextualActions')
);

export const AsyncContextualActions: React.FC<ContextualActionsProps> = props => {
	return (
		<React.Suspense fallback={<Spin size="small" />}>
			<LazyContextualActions {...props} />
		</React.Suspense>
	);
};

const LazyAutomationsButton = React.lazy(() => import('./AutomationsButton'));

export const AsyncAutomationsButton: React.FC<AutomationsButtonProps> = props => {
	const skeletonButtonPropsOverrides: Omit<SkeletonButtonProps, 'active'> = {
		...(props.buttonProps?.block && { block: props.buttonProps.block }),
		...(props.buttonProps?.shape && { shape: props.buttonProps.shape }),
		...(props.buttonProps?.size && {
			size: props.buttonProps.size === 'middle' ? 'default' : props.buttonProps.size,
		}),
	};

	return (
		<React.Suspense
			fallback={<Skeleton.Button active {...skeletonButtonPropsOverrides} />}
		>
			<LazyAutomationsButton {...props} />
		</React.Suspense>
	);
};

const LazyAutomationsDropDownLabel = React.lazy(
	() => import('./AutomationsDropDownLabel')
);

export const AsyncAutomationsDropDownLabel: React.FC<AutomationsButtonProps> = props => {
	return (
		<React.Suspense fallback={t('workflows-pilet:common.automations')}>
			<LazyAutomationsDropDownLabel {...props} />
		</React.Suspense>
	);
};

const LazyLoadFlowsByTrigger = React.lazy(() => import('./LoadFlowsByTrigger'));

export const AsyncLoadFlowsByTrigger: React.FC<LoadFlowsProps> = props => {
	return (
		<React.Suspense fallback={<Spin size="small" />}>
			<LazyLoadFlowsByTrigger {...props} />
		</React.Suspense>
	);
};
