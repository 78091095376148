import React from 'react';
import { WarningDiamondFilled } from '@sharefiledev/icons';
import { theme } from 'antd';
import { SmallMessageBanner } from './SmallMessageBanner';

type WarningMessageProps = {
	message: string | JSX.Element;
};

export const WarningMessage: React.FC<WarningMessageProps> = ({ message }) => {
	const { token } = theme.useToken();

	return (
		<SmallMessageBanner
			message={message}
			iconElement={<WarningDiamondFilled color={token.colorWarning} size={16} />}
			background={token.colorWarningBg}
		/>
	);
};
