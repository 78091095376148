import { parseResourceName, ResourceName } from '@sharefile/domino-resource-name-utils';

export function getResolvedResourceName(fqn: string): ResourceName {
	let resourceName = parseResourceName(fqn);
	// temporary workaround for situations where fqns are not used
	if (typeof resourceName !== 'object') {
		resourceName = {
			namespace: 'ShareFile',
			package: 'sharefile',
			name: fqn,
			version: undefined,
		};
	}
	return resourceName;
}
