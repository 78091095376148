import { CSSProperties } from 'react';
import {
	Action,
	ArgumentCollection,
	FlowSymbol,
	Handle,
} from '@sharefile/domino-builder';
import { Type } from '@sharefile/domino-types';
import { ConfiguratorResolver } from '../FlowDesigner/configurator';

export interface ArgumentFormProps {
	collection: ArgumentCollection;
	configuratorResolver: ConfiguratorResolver;
	onValueChange(handle: Handle, value: any): void;
	onSymbolQuery?(handle: Handle): FlowSymbol[];
	onActionQuery?(handle: Handle): Action;
	applyValidation: boolean;
	getTypeDefinition(name: string): Promise<Type>;
	options: any;
}

export enum ExtensionType {
	APPSHELL = 'appshell',
	PROJECTS = 'projects',
	FILESPLUGIN = 'filesplugin',
}

export interface RunResults {
	successes: RunResult[];
	failures: RunResult[];
}

interface RunResult {
	name: string;
}

export type Styles = Record<string, CSSProperties>;

export interface SelectedFlow {
	name: string;
	id: string;
}
