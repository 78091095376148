import React from 'react';
import { sizes } from '@sharefiledev/antd-config';
import { Flex, theme } from 'antd';

export type SmallMessageBannerProps = {
	message: string | JSX.Element;
	background: string;
	iconElement: JSX.Element; // distinct from `icon` being used in certain places, which requires a "component"
};

export function SmallMessageBanner(props: SmallMessageBannerProps) {
	const { token } = theme.useToken();

	return (
		<Flex
			align="center"
			gap={sizes.XS}
			style={{
				background: props.background,
				borderRadius: '2px',
				boxSizing: 'border-box',
				padding: `${sizes.XXS} ${sizes.XS}`,
				fontSize: token.fontSize,
				fontWeight: token.fontWeightStrong,
				lineHeight: sizes.base,
			}}
		>
			<div style={{ alignSelf: 'start', paddingTop: '3px' }}>{props.iconElement}</div>
			<span>{props.message}</span>
		</Flex>
	);
}
