import React from 'react';
import {
	ButtonPlayCircle,
	EmptyList,
	Flash,
	IconProps,
	Unknown,
} from '@sharefiledev/icons';
import { Avatar } from 'antd';
import { ResourceType } from '../../data/clients';
import { useFetchIconByName } from './useFetchIconByName';

export enum IconType {
	EmptyStateIcon = 'emptyStateIcon',
}

export type ResourceIconProps = IconProps & {
	resourceType: ResourceType;
	iconTag?: string;
	iconType?: IconType;
};

export function ResourceIcon({
	resourceType,
	iconTag,
	iconType,
	...props
}: ResourceIconProps) {
	const { data: icons } = useFetchIconByName(iconTag);

	return iconType === IconType.EmptyStateIcon && !!icons?.emptyStateIcon ? (
		<icons.emptyStateIcon {...props} />
	) : !!icons?.defaultIcon ? (
		<icons.defaultIcon {...props} />
	) : resourceType === ResourceType.Action ? (
		<Flash {...props} data-testid="action-icon" />
	) : resourceType === ResourceType.Trigger ? (
		<ButtonPlayCircle {...props} data-testid="trigger-icon" />
	) : iconTag?.match(/Initials\?(\w+)$/) ? (
		<Avatar size={24} style={{ flexShrink: 0 }}>
			{iconTag.split('?')[1]}
		</Avatar>
	) : iconType === IconType.EmptyStateIcon ? (
		<EmptyList {...props} data-testid="empty-state-icon" />
	) : (
		<Unknown {...props} data-testid="unknown-icon" />
	);
}
