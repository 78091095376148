import React, { lazy, Suspense } from 'react';
import { OverlayModalComponentProps } from '@sharefiledev/sharefile-appshell';
import { Spin } from 'antd';
import { Props as StorageFileExportModalProps } from '../FileExport/components/StorageFileExportModal';
import {
	AutomationsOverlayParams,
	DesignFlowOverlayParams,
	internalWorkflowsPiletOverlays,
	LaunchFlowOverlayParams,
	RunDetailsOverlayParams,
	workflowsPiletOverlays,
} from '../sdk';

export * from './getDefaultOverlayOptions';

export type LaunchFlowOverlayProps = LaunchFlowOverlayParams &
	Pick<
		OverlayModalComponentProps<typeof workflowsPiletOverlays.launchFlow>,
		'completeInteraction' | 'cancelInteraction'
	>;

const LazyLaunchFlowOverlayContent = lazy(() => import('./LaunchFlowOverlayContent'));
export const LaunchFlowOverlay = (props: LaunchFlowOverlayProps) => (
	<Suspense fallback={<Spin />}>
		<LazyLaunchFlowOverlayContent {...props} />
	</Suspense>
);

const LazyStorageFileExportModal = lazy(
	() => import('../FileExport/components/StorageFileExportModal')
);
export const AsyncStorageFileExportModal: React.FC<
	StorageFileExportModalProps
> = props => (
	<Suspense fallback={<Spin />}>
		<LazyStorageFileExportModal {...props} />
	</Suspense>
);

export type AutomationsOverlayProps = AutomationsOverlayParams &
	Pick<
		OverlayModalComponentProps<typeof workflowsPiletOverlays.launchFlow>,
		'completeInteraction' | 'cancelInteraction'
	>;

const LazyAutomationsOverlayContent = lazy(() => import('./AutomationsOverlayContent'));
export const AutomationsOverlay = (props: AutomationsOverlayProps) => (
	<Suspense fallback={<Spin />}>
		<LazyAutomationsOverlayContent {...props} />
	</Suspense>
);

export type DesignFlowOverlayProps = DesignFlowOverlayParams &
	Pick<
		OverlayModalComponentProps<typeof workflowsPiletOverlays.designFlow>,
		'completeInteraction' | 'cancelInteraction'
	>;

const LazyDesignFlowOverlayContent = lazy(() => import('./DesignFlowOverlayContent'));
export const DesignFlowOverlay = (props: DesignFlowOverlayProps) => (
	<Suspense fallback={<Spin />}>
		<LazyDesignFlowOverlayContent {...props} />
	</Suspense>
);

export type RunDetailsOverlayProps = RunDetailsOverlayParams &
	Pick<
		OverlayModalComponentProps<typeof internalWorkflowsPiletOverlays.runDetails>,
		'completeInteraction' | 'cancelInteraction'
	>;

const LazyRunDetailsOverlayContent = lazy(() => import('./RunDetailsOverlayContent'));
export const RunDetailsOverlay = (props: RunDetailsOverlayProps) => (
	<Suspense fallback={<Spin />}>
		<LazyRunDetailsOverlayContent {...props} />
	</Suspense>
);
