import * as dominoCoreSDK from '@citrite/domino-core-sdk';
import {
	Blueprint,
	BlueprintInfo,
	Flow,
	FlowInfo,
	FlowInfoListWithPagination,
	Resource,
	ResourceInfo,
	RunInfo,
	RunInfoListWithPagination,
	RunLogs,
	TriggerEvent,
	TriggerFlowResponse,
} from '@citrite/domino-core-sdk';
import { getFeatureFlagValue } from '@citrite/feature-flags';
import { parseRID } from '@sharefile/rid-utils';
import { AxiosResponse } from 'axios';
import { getResolvedResourceName } from '../data/helpers';
import { FeatureFlag } from '../FeatureFlags';
import { getLogger } from '../logger';
import {
	CancelRunParams,
	CreateFlowParams,
	DeleteFlowParams,
	ExecuteTypeSpecParams,
	GetBlueprintParams,
	GetFlowParams,
	GetResourceEventsParams,
	GetResourceParams,
	GetResourcesByNamespacePackageParams,
	GetResourcesParams,
	GetRunLogsParams,
	GetRunParams,
	GetRunsParams,
	GetTriggerEventParams,
	ListBlueprintsParams,
	ListFlowsParams,
	ToggleFlowActiveStateParams,
	TriggerRunParams,
	TypeSpecExecutionResult,
	UpdateFlowParams,
} from './types';

export interface DominoClient {
	getFlows(params?: ListFlowsParams): Promise<FlowInfoListWithPagination | FlowInfo[]>;
	getFlow(params: GetFlowParams): Promise<Flow>;
	createFlow(params: CreateFlowParams): Promise<Flow>;
	deleteFlow(params: DeleteFlowParams): Promise<void>;
	updateFlow(params: UpdateFlowParams): Promise<void>;
	toggleFlowActiveState(params: ToggleFlowActiveStateParams): Promise<void>;
	getBlueprints(params?: ListBlueprintsParams): Promise<BlueprintInfo[]>;
	getBlueprint(params: GetBlueprintParams): Promise<Blueprint>;
	getRuns(params?: GetRunsParams): Promise<RunInfo[] | RunInfoListWithPagination>;
	triggerRun(params: TriggerRunParams): Promise<TriggerFlowResponse>;
	getTriggerEvent(params: GetTriggerEventParams): Promise<TriggerEvent>;
	cancelRun(params: CancelRunParams): Promise<void>;
	getRun(params: GetRunParams): Promise<RunInfo>;
	getRunLogs(params: GetRunLogsParams): Promise<RunLogs>;
	triggers: {
		realtime(): Promise<any>;
		getResourceEvents(params: GetResourceEventsParams): Promise<any>;
	};
	resources: {
		listResources(params: GetResourcesParams): Promise<ResourceInfo[]>;
		listByNamespacePackage(
			params: GetResourcesByNamespacePackageParams
		): Promise<ResourceInfo[]>;
		get(params: GetResourceParams): Promise<Resource>;
		execute(params: ExecuteTypeSpecParams): Promise<TypeSpecExecutionResult>;
	};
}

export interface DominoClientConfiguration {
	baseUrl: string;
	/**
	 * The token acquisition callback is called before a request if there is no cached token.
	 * It's additionally called whenever the RightSignature API returns a 401 authentication
	 * challenge response, in order to refresh an expired token. Avoid providing a cached token
	 * to this callback; caching is handled in the SDK.
	 */
	acquireAccessToken?: () => Promise<string>;
}

export function createDominoClient(
	configuration: DominoClientConfiguration
): DominoClient {
	const sharedConfig = new dominoCoreSDK.Configuration({
		basePath: configuration.baseUrl,
		baseOptions: {
			timeout: 60000,
		},
	});

	const flowsApi = new dominoCoreSDK.FlowsApi(sharedConfig);
	const blueprintsApi = new dominoCoreSDK.BlueprintsApi(sharedConfig);
	const eventsApi = new dominoCoreSDK.EventsApi(sharedConfig);
	const runsApi = new dominoCoreSDK.RunsApi(sharedConfig);
	const resourcesApi = new dominoCoreSDK.ResourcesApi(sharedConfig);
	const realtimeApi = new dominoCoreSDK.RealtimeApi(sharedConfig);
	const resourceEventsApi = new dominoCoreSDK.ResourceEventsApi(sharedConfig);

	// TODO: this workaround/data fix is needed till we have some logic to clean up old runs
	// for runs that don't have created/updated time stamp, we will fallback to date: 1st Jan 2023
	const fixRunDateTime = (dateTime: string) => {
		return dateTime === '$undefined' ? '2023-01-01T00:00:00.000Z' : dateTime;
	};

	const resolveUserID = (userIdOrRID: string) => {
		try {
			const rid = parseRID(userIdOrRID);
			return 'sf-' + rid.leaf.id;
		} catch {
			return userIdOrRID;
		}
	};

	return {
		getFlows(params?: ListFlowsParams) {
			return apiProxy<FlowInfoListWithPagination | FlowInfo[]>(async headers => {
				const response: AxiosResponse = await flowsApi.listFlows(
					params?.limit,
					params?.tag,
					params?.triggerName,
					params?.excludeInactive,
					params?.paginate ?? true,
					params?.continuationToken,
					params?.searchQuery,
					params?.containerRID,
					params?.includeMetadata,
					params?.includeHidden,
					{ headers }
				);

				if (params?.paginate === false) {
					(response?.data as FlowInfo[])?.forEach(flow => {
						flow.creator = resolveUserID(flow.creator);
						flow.modifier = resolveUserID(flow.modifier);
					});
				} else {
					(response?.data as FlowInfoListWithPagination)?.flows?.forEach(flow => {
						flow.creator = resolveUserID(flow.creator);
						flow.modifier = resolveUserID(flow.modifier);
					});
				}

				return response;
			}, 'listFlows');
		},
		getFlow({ id }: GetFlowParams) {
			return apiProxy<Flow>(async headers => {
				const flow = await flowsApi.getFlow(id, { headers });
				if (!!flow.data) {
					flow.data.creator = resolveUserID(flow.data.creator);
					flow.data.modifier = resolveUserID(flow.data.modifier);
				}
				return flow;
			}, 'getFlow');
		},
		createFlow({ definition, containerRID }: CreateFlowParams) {
			return apiProxy<Flow>(async headers => {
				const flow = await flowsApi.createFlow(definition, containerRID, { headers });
				if (!!flow.data) {
					flow.data.creator = resolveUserID(flow.data.creator);
					flow.data.modifier = resolveUserID(flow.data.modifier);
				}
				return flow;
			}, 'createFlow');
		},
		updateFlow({ id, definition }: UpdateFlowParams) {
			return apiProxy<void>(
				headers => flowsApi.updateFlow(id, definition, { headers }),
				'updateFlow'
			);
		},
		deleteFlow({ id }: DeleteFlowParams) {
			return apiProxy<void>(
				headers => flowsApi.deleteFlow(id, { headers }),
				'deleteFlow'
			);
		},
		toggleFlowActiveState({ id, state }: ToggleFlowActiveStateParams) {
			return apiProxy<void>(
				headers => {
					return state
						? flowsApi.enableFlow(id, { headers })
						: flowsApi.disableFlow(id, { headers });
				},
				state ? 'enableFlow' : 'disableFlow'
			);
		},
		getBlueprints(params?: ListBlueprintsParams) {
			return apiProxy<BlueprintInfo[]>(
				headers => blueprintsApi.listBlueprints(params?.tag, { headers }),
				'listBlueprints'
			);
		},
		getBlueprint({ id }: GetBlueprintParams) {
			return apiProxy<Blueprint>(
				headers => blueprintsApi.getBlueprint(id, { headers }),
				'getBlueprint'
			);
		},
		getRuns(params?: GetRunsParams) {
			return apiProxy<RunInfo[] | RunInfoListWithPagination>(async headers => {
				const response = await runsApi.listRuns(
					params?.limit,
					params?.flowId,
					params?.containerRID,
					params?.paginate,
					params?.continuationToken,
					params?.searchQuery,
					{ headers }
				);

				if (params?.paginate === false) {
					(response?.data as RunInfo[])?.forEach(run => {
						run.created = fixRunDateTime(run.created);
						run.updated = fixRunDateTime(run.updated);
						run.creator = resolveUserID(run.creator);
					});
				} else {
					(response?.data as RunInfoListWithPagination)?.runs?.forEach(run => {
						run.created = fixRunDateTime(run.created);
						run.updated = fixRunDateTime(run.updated);
						run.creator = resolveUserID(run.creator);
					});
				}
				return response;
			}, 'listRuns');
		},
		cancelRun({ runId }: CancelRunParams) {
			return apiProxy<void>(
				headers => runsApi.cancelRunById(runId, { headers }),
				'cancelRunById'
			);
		},
		triggerRun({ id, fields, runName, callback, _debugger }: TriggerRunParams) {
			const data = {
				payload: fields ? JSON.stringify({ ...fields }) : undefined,
				runName,
			};

			return apiProxy<TriggerFlowResponse>(
				headers => eventsApi.triggerByFlowId(id, data, callback, _debugger, { headers }),
				'triggerByFlowId'
			);
		},
		getTriggerEvent({ triggerId }: GetTriggerEventParams) {
			return apiProxy<TriggerEvent>(
				headers => eventsApi.getTriggerStatus(triggerId, { headers }),
				'getTriggerStatus'
			);
		},
		getRun({ id }: GetRunParams) {
			return apiProxy<RunInfo>(async headers => {
				const run = await runsApi.getRun(id, { headers });
				if (!!run.data) {
					run.data.creator = resolveUserID(run.data.creator);
				}
				return run;
			}, 'getRun');
		},
		getRunLogs({ runId, limit, nextToken, startTime, endTime }: GetRunLogsParams) {
			return apiProxy<RunLogs>(
				headers =>
					runsApi.getRunLogs(runId, limit, nextToken, startTime, endTime, { headers }),
				'getRunLogs'
			);
		},
		triggers: {
			realtime() {
				return apiProxy<any>(headers => realtimeApi.realtime({ headers }), 'realtime');
			},
			getResourceEvents({ containerRID }: GetResourceEventsParams) {
				return apiProxy<any>(async headers => {
					return (await resourceEventsApi.getResourceEventSchemas(containerRID, {
						headers,
					})) as any;
				}, 'getResourceEvents');
			},
		},
		resources: {
			listResources({ type, category }: GetResourcesParams) {
				return apiProxy<ResourceInfo[]>(
					headers => resourcesApi.listResources(type, category, { headers }),
					'listResources'
				);
			},
			listByNamespacePackage({
				type,
				namespace,
				_package,
			}: GetResourcesByNamespacePackageParams) {
				if (!!_package) {
					return apiProxy<ResourceInfo[]>(
						headers =>
							resourcesApi.listResourcesByPackage(namespace, _package, type, { headers }),
						'listResourcesByPackage'
					);
				} else {
					return apiProxy<ResourceInfo[]>(
						headers =>
							resourcesApi.listResourcesByNamespace(namespace, type, { headers }),
						'listResourcesByNamespace'
					);
				}
			},
			get({ namespace, _package, name, version }: GetResourceParams) {
				return apiProxy<Resource>(
					headers =>
						resourcesApi.getResource(namespace, _package, name, version, false, {
							headers,
						}),
					'getResource'
				);
			},
			execute({ type, specName, specArgs, typeArgs }: ExecuteTypeSpecParams) {
				const {
					namespace,
					package: _package,
					name,
					version,
				} = getResolvedResourceName(type);

				return apiProxy<any>(
					headers =>
						resourcesApi.executeResourceMethod(
							namespace,
							_package,
							name,
							{
								specName,
								specArgs,
								typeArgs: typeArgs ?? {},
							},
							version,
							{ headers }
						),
					'executeTypeSpec'
				);
			},
		},
	};

	async function apiProxy<T>(
		proxyFn: (headers: any) => Promise<AxiosResponse<T>>,
		proxyLabel: string
	): Promise<T> {
		let response;
		try {
			if (getFeatureFlagValue(FeatureFlag.EnableBFFOnWorkflowsPilet)) {
				response = await proxyFn({
					'X-BFF-CSRF': 'true',
				});
			} else {
				const token = await getToken();
				response = await proxyFn({
					Authorization: `Bearer ${token}`,
				});
			}
		} catch (error) {
			getLogger().logError(error, {
				customMessage: `Domino API failure with ${proxyLabel}`,
			});
			throw error;
		}

		if (response.status < 200 || response.status > 399) {
			throw new Error(JSON.stringify(response));
		}
		return response.data;
	}

	async function getToken() {
		const freshToken = await configuration.acquireAccessToken();
		return freshToken;
	}
}
