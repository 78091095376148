import React from 'react';
import { sizes } from '@sharefiledev/antd-config';
import { Flex, Switch, theme } from 'antd';

export interface ToggleProps {
	analyticsId?: string;
	id?: string;
	on: boolean;
	disabled?: boolean;
	onToggle(): void;
	active?: boolean;
}

export function Toggle(props: ToggleProps) {
	const { token } = theme.useToken();
	return (
		<Flex align="center" gap={sizes.XS} style={{ height: token.controlHeight }}>
			<Switch
				data-analytics-id={props.analyticsId}
				id={props.id}
				checked={props.on}
				onClick={props.onToggle}
				loading={props.active}
				disabled={props.disabled || props.active}
				size="small"
			/>
		</Flex>
	);
}
