import React from 'react';
import { WarningDiamondFilled } from '@sharefiledev/icons';
import { theme } from 'antd';
import { SmallMessageBanner } from './SmallMessageBanner';

type ErrorMessageProps = {
	message: string | JSX.Element;
};

export const ErrorMessage: React.FC<ErrorMessageProps> = ({ message }) => {
	const { token } = theme.useToken();

	return (
		<SmallMessageBanner
			background={token.colorErrorBg}
			iconElement={<WarningDiamondFilled color={token.colorError} size={16} />}
			message={message}
		/>
	);
};
