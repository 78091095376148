import {
	Blueprint,
	BlueprintInfo,
	Flow,
	FlowInfo,
	FlowInfoListWithPagination,
	Resource,
	ResourceInfo,
	RunInfo,
	RunInfoListWithPagination,
	RunLogs,
	TriggerEvent,
	TriggerFlowResponse,
} from '@citrite/domino-core-sdk';
import { PiletApi } from '@sharefiledev/sharefile-appshell';
import { createDominoClient, DominoClient } from '../../../domino-sdk';
import {
	CancelRunParams,
	CreateFlowParams,
	DeleteFlowParams,
	ExecuteTypeSpecParams,
	GetBlueprintParams,
	GetFlowParams,
	GetResourceEventsParams,
	GetResourceParams,
	GetResourcesByNamespacePackageParams,
	GetResourcesParams,
	GetRunLogsParams,
	GetRunParams,
	GetRunsParams,
	GetTriggerEventParams,
	ListBlueprintsParams,
	ListFlowsParams,
	ToggleFlowActiveStateParams,
	TriggerRunParams,
	TypeSpecExecutionResult,
	UpdateFlowParams,
} from '../../../domino-sdk/types';
import { getApiBaseUrl, getApiBaseUrlNoBFF } from '../../helpers/getApiBaseUrl';
import { acquireAccessToken } from './acquireAccessToken';
import { withGeo } from './helpers';

export class DominoClientWrapper implements DominoClient {
	private initializedClient: DominoClient;

	public Initialize(dominoClient: DominoClient) {
		this.initializedClient = dominoClient;
	}

	public getFlows(params?: ListFlowsParams): Promise<FlowInfoListWithPagination> {
		if (params?.paginate === true) {
			return this.initializedClient.getFlows(
				params
			) as Promise<FlowInfoListWithPagination>;
		}

		return new Promise<FlowInfoListWithPagination>(async resolve => {
			const flows = await this.initializedClient.getFlows(params);
			resolve({ flows: flows as FlowInfo[] });
		});
	}

	public getFlow(params: GetFlowParams): Promise<Flow> {
		return this.initializedClient.getFlow(params);
	}

	public createFlow(params: CreateFlowParams): Promise<Flow> {
		return this.initializedClient.createFlow(params);
	}

	public deleteFlow(params: DeleteFlowParams): Promise<void> {
		return this.initializedClient.deleteFlow(params);
	}

	public updateFlow(params: UpdateFlowParams): Promise<void> {
		return this.initializedClient.updateFlow(params);
	}

	public toggleFlowActiveState(params: ToggleFlowActiveStateParams): Promise<void> {
		return this.initializedClient.toggleFlowActiveState(params);
	}

	public getBlueprints(params?: ListBlueprintsParams): Promise<BlueprintInfo[]> {
		return this.initializedClient.getBlueprints(params);
	}

	public getBlueprint(params: GetBlueprintParams): Promise<Blueprint> {
		return this.initializedClient.getBlueprint(params);
	}

	public getRuns(params: GetRunsParams): Promise<RunInfo[] | RunInfoListWithPagination> {
		return this.initializedClient.getRuns(params);
	}

	public triggerRun(params: TriggerRunParams): Promise<TriggerFlowResponse> {
		return this.initializedClient.triggerRun(params);
	}

	public getTriggerEvent(params: GetTriggerEventParams): Promise<TriggerEvent> {
		return this.initializedClient.getTriggerEvent(params);
	}

	public cancelRun(params: CancelRunParams): Promise<void> {
		return this.initializedClient.cancelRun(params);
	}

	public getRun(params: GetRunParams): Promise<RunInfo> {
		return this.initializedClient.getRun(params);
	}

	public getRunLogs(params: GetRunLogsParams): Promise<RunLogs> {
		return this.initializedClient.getRunLogs(params);
	}

	public triggers = {
		realtime: (): Promise<{ token: string }> => {
			return this.initializedClient.triggers.realtime();
		},
		getResourceEvents: (params: GetResourceEventsParams): Promise<any> => {
			return this.initializedClient.triggers.getResourceEvents(params);
		},
	};

	public resources = {
		listResources: (params: GetResourcesParams): Promise<ResourceInfo[]> => {
			return this.initializedClient.resources.listResources(params);
		},
		listByNamespacePackage: (
			params: GetResourcesByNamespacePackageParams
		): Promise<ResourceInfo[]> => {
			return this.initializedClient.resources.listByNamespacePackage(params);
		},
		get: (params: GetResourceParams): Promise<Resource> => {
			return this.initializedClient.resources.get(params);
		},
		execute: (params: ExecuteTypeSpecParams): Promise<TypeSpecExecutionResult> => {
			return this.initializedClient.resources.execute(params);
		},
	};
}

export const dominoApiClient: DominoClient = new DominoClientWrapper();

export function configureDominoApiClient_Legacy() {
	const baseUrl = getApiBaseUrlNoBFF();

	(dominoApiClient as DominoClientWrapper).Initialize(
		createDominoClient({
			baseUrl: withGeo(baseUrl),
			acquireAccessToken,
		})
	);
}

export function configureDominoApiClient(app: PiletApi) {
	(dominoApiClient as DominoClientWrapper).Initialize(
		createDominoClient({
			baseUrl: getApiBaseUrl(app),
		})
	);
}
