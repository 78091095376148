import { DominoBuilderErrorSeverity } from '@sharefile/domino-builder';
import { AlertInfo } from '@sharefile/domino-types';

export const getSeverityLevelForAlert = (alert: AlertInfo) => {
	const severity =
		alert.level &&
		DominoBuilderErrorSeverity[
			alert.level.split('.').pop()! as keyof typeof DominoBuilderErrorSeverity
		];
	return !!severity ? severity : DominoBuilderErrorSeverity.INFO;
};
