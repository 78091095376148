import React from 'react';
import { useQuery } from '@tanstack/react-query';
import { getResourceRegistryClient } from '../data/clients';
import { t } from '../util';

export const ActionName = (props: { call?: string; label?: string }) => {
	const resourceRegistryClient = getResourceRegistryClient();
	const {
		isError: errorGettingActionLabel,
		isLoading: loadingActionLabel,
		data: actionLabel,
	} = useQuery(
		['getActionOutputByType', props.call],
		async () => {
			const actionRegistryEntry = await resourceRegistryClient.fetchResource(props.call);
			return actionRegistryEntry?.label ?? actionRegistryEntry?.name ?? '';
		},
		{ enabled: !!!props.label }
	);

	if (!!props.label) {
		return <span>{props.label}</span>;
	}

	return (
		<>
			{errorGettingActionLabel
				? t('workflows-pilet:run_logs.log_strings.error_loading_action_label')
				: loadingActionLabel
				? t('workflows-pilet:run_logs.log_strings.loading_action_label')
				: actionLabel}
		</>
	);
};
