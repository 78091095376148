/* tslint:disable */
/**
 * This file is autogenerated using @sharefiledev/solution-view-engine-tools.
 *
 * Run 'sve typegen <relative path to manifest file>'
 *
 * Types are generated according to specification in a file that conforms to the UI Manifest schema:
 * https://us-solutionviewengine.sharefiletest.io/ui-manifest-v2.json
 */
import type {
	PiletApi,
	PiralCustomExtensionSlotMap as ShellPiralCustomExtensionSlotMap,
} from '@sharefiledev/sharefile-appshell';
import type { BlockParams } from '@sharefiledev/solution-view-engine';

export interface ShellProps {
	piletApi: PiletApi;
}
/** Block parameters for urn:block:workflows-pilet:launch-flow */

export interface LaunchFlowBlockParams {
	flowRID: string;
	buttonText: LocalizedString;
	buttonProps?: {
		type?: 'primary' | 'default' | 'dashed' | 'link' | 'text';
		size?: 'small' | 'middle' | 'large';
		shape?: 'circle' | 'round' | 'default';
		block?: boolean;
		danger?: boolean;
		disabled?: boolean;
		ghost?: boolean;
		htmlType?: 'button' | 'submit' | 'reset';
		loading?: boolean;
		target?: string;
		href?: string;
	};
	collectUserInput?: boolean;
	confirmationModalText?: LocalizedString;
	context?: {
		[k: string]: unknown;
	};
	/**
	 * An event name that will be triggered on success. By default this will be urn:sf-event:workflow-pilet:launch-flow:success
	 */
	onSuccessEventName?: string;
	/**
	 * An event name that will be triggered on success. By default this will be urn:sf-event:workflow-pilet:launch-flow:error
	 */
	onErrorEventName?: string;
}
/**
 * Localized String Values
 */
export interface LocalizedString {
	en?: string;
	es?: string;
	de?: string;
	fr?: string;
	it?: string;
	ja?: string;
	nl?: string;
	'pt-BR'?: string;
}
/** Block parameters for urn:block:workflows-pilet:workflow-visualization */

export interface WorkflowVisualizationBlockParams {
	flowRID: string;
	height?: string;
	width?: string;
}

export namespace BlockNames {
	export const LaunchFlow = 'urn:block:workflows-pilet:launch-flow' as const;
	export type LaunchFlow = 'urn:block:workflows-pilet:launch-flow';
	export const WorkflowVisualization =
		'urn:block:workflows-pilet:workflow-visualization' as const;
	export type WorkflowVisualization = 'urn:block:workflows-pilet:workflow-visualization';
}

export namespace ZoneTypes {}

export type LaunchFlowProps = ShellProps & BlockParams & LaunchFlowBlockParams;
export type WorkflowVisualizationProps = ShellProps &
	BlockParams &
	WorkflowVisualizationBlockParams;

declare module '@sharefiledev/sharefile-appshell' {
	interface PiralCustomExtensionSlotMap {
		['urn:block:workflows-pilet:launch-flow']: BlockParams & LaunchFlowBlockParams;
		['urn:block:workflows-pilet:workflow-visualization']: BlockParams &
			WorkflowVisualizationBlockParams;
	}
}

declare module '@sharefiledev/solution-view-engine' {
	interface BlockCustomExtensionSlotMap extends ShellPiralCustomExtensionSlotMap {}

	interface PiletExtensionApi extends PiletApi {}

	interface ViewEngineCustomZoneParametersMap {}

	interface ViewEngineCustomZoneContractDataMap {}
}
