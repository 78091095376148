import styled from '@emotion/styled';
import { typography } from '@sharefiledev/flow-web';
import { ChipColors } from './Chip';

type ChipContainerProps = {
	isSelected: boolean;
	colors: ChipColors;
};

export const ChipContainer = styled.span((props: ChipContainerProps) => ({
	userSelect: 'none' as any,
	userAction: 'none',
	display: 'inline-flex',
	padding: '2px 2px 2px 4px',
	borderRadius: '2px',
	color: props.colors.textColor,
	background: !!!props.isSelected
		? props.colors.background
		: props.colors.selectedBackground,
	alignItems: 'center',
	gap: '4px',
	fontSize: typography.sizes.sml,
	fontWeight: typography.weights.semiBold,
	lineHeight: typography.sizes.base,
	':hover': {
		background: props.colors.hoverBackground,
	},
}));

type ChipDeleteProps = {
	colors: ChipColors;
};

export const ChipDelete = styled.span((props: ChipDeleteProps) => ({
	display: 'flex',
	cursor: 'pointer',
	padding: '4px',
	borderRadius: '2px',
	alignItems: 'flex-start',
	background: 'rgba(255, 255, 255, 0)',
	gap: '8px',
	color: props.colors.deleteButton.textColor,
	':hover': {
		background: 'rgba(255, 255, 255, 0.7)',
		color: props.colors.deleteButton.hoverTextColor,
	},
}));
